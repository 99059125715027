/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line @typescript-eslint/no-require-imports
const ReactDOMClient = require('react-dom/client')

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOMClient.createRoot(container, {
      hydrateRoot: true,
      hydrationOptions: { onHydrated: callback },
    }).render(element)
  }
}
